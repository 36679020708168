<template>
	<StandardMindmap :prop_map_id="$route.params.id"></StandardMindmap>
</template>

<script>
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";

export default {
	name: "StandardMindmapExplorePage",

	components: {
		StandardMindmap,
	},
};
</script>
